<template>
  <section class="my--leads-content">
    <div class="row">
      <div class="col-12 col-md-6 left--col">
        <div class="all--leads">
          <div
            class="lead--summary"
            :class="{ read: lead.read }"
            v-for="(lead, index) in leads"
            :key="`leads-${index}`"
            @click="openLead(lead)"
          >
            <div class="lead--name">{{ lead.name }}</div>
            <div class="lead--message">{{ shortMessage(lead.message) }}</div>
            <div class="lead--created-at">{{ $date.format(lead.created_at, 'dd MMM yyyy') }}</div>
          </div>
          <div class="empty--lead" v-if="leads.length === 0">
            {{ $t('leads.empty') }}
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 right--col">
        <div class="selected--lead" v-if="selectedLead">
          <div class="lead--header">
            <div>
              <div class="lead--name">{{ selectedLead.name }}</div>
              <div class="lead--created-at">
                {{ $date.format(selectedLead.created_at, 'dd MMM yyyy') }}
              </div>
            </div>
            <div class="lead--actions">
              <div class="single--action" @click="archiveLead(selectedLead)">
                <div class="d-flex justify-center">
                  <v-icon v-if="selectedLead.archive">mdi-archive-arrow-up-outline</v-icon>
                  <v-icon v-else>mdi-archive-arrow-down-outline</v-icon>
                </div>
                <div>
                  <span v-if="selectedLead.archive">{{ $t('leads.unsetArchive') }}</span>
                  <span v-else>{{ $t('leads.setArchive') }}</span>
                </div>
              </div>
              <div class="single--action" @click="deleteLead(selectedLead)">
                <div class="d-flex justify-center">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </div>
                <div>{{ $t('leads.delete') }}</div>
              </div>
              <div
                class="single--action"
                v-if="selectedLead.read"
                @click="unreadLead(selectedLead)"
              >
                <div class="d-flex justify-center">
                  <v-icon>mdi-eye-off-outline</v-icon>
                </div>
                <div>{{ $t('leads.unsetRead') }}</div>
              </div>
            </div>
          </div>
          <div class="lead--message">{{ selectedLead.message }}</div>
          <div class="lead--listing" v-if="selectedLead.listing">
            <a :href="listingUrl(selectedLead.listing.url)" target="_blank">{{
              listingUrl(selectedLead.listing.url)
            }}</a>
          </div>
          <a :href="`mailto:${selectedLead.email}`" target="_blank" class="lead--email">
            <img src="@/assets/img/icons/mail-3.svg" />
            <div>{{ selectedLead.email }}</div>
          </a>
          <a :href="`tel:${selectedLead.phone_number}`" class="lead--phone">
            <img src="@/assets/img/icons/phone-2.svg" />
            <div>{{ selectedLead.phone_number }}</div>
          </a>
        </div>
        <div class="empty--lead" v-else>
          {{ $t('leads.notSelected') }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'leads-content',
  components: {},
  computed: {
    ...mapState({
      leads: (state) => state.enquiry.leads,
      page: (state) => state.enquiry.page,
    }),
    selectedLead: {
      get() {
        return this.$store.state.enquiry.selectedLead;
      },
      set(value) {
        this.$store.commit('enquiry/SET_SELECTED_LEAD', value);
      },
    },
  },
  methods: {
    shortMessage(message) {
      let newMessage = JSON.parse(JSON.stringify(message));
      if (newMessage.length > 120) newMessage = newMessage.substring(0, 120) + '...';
      return newMessage;
    },
    listingUrl(url) {
      let newUrl = JSON.parse(JSON.stringify(url));
      newUrl = process.env.VUE_APP_USER_URL + newUrl;
      return newUrl;
    },
    async openLead(lead) {
      this.selectedLead = lead;
      if (lead.read !== true) {
        await this.$store.dispatch('enquiry/setRead', {
          uuid: lead.uuid,
          value: true,
        });
        lead.read = true;
      }
    },
    async archiveLead(lead) {
      await this.$store.dispatch('enquiry/setArchive', {
        uuid: lead.uuid,
        value: !lead.archive,
      });
      lead.archive = !lead.archive;
      await this.refreshPage();
    },
    async deleteLead(lead) {
      let self = this;
      this.$swal({
        title: this.$i18n.t('leads.deleteWarningT'),
        text: this.$i18n.t('leads.deleteWarningD'),
        type: 'error',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('general.yes'),
        cancelButtonText: this.$i18n.t('general.cancel'),
        allowOutsideClick: true,
      }).then(async function (result) {
        if (result.value) {
          await self.$store.dispatch('enquiry/deleteEnquiry', {
            uuid: lead.uuid,
          });
          self.$swal(self.$t('general.success'), self.$t('leads.deleteSuccess'), 'success');
          await self.refreshPage();
        }
      });
    },
    async unreadLead(lead) {
      await this.$store.dispatch('enquiry/setRead', {
        uuid: lead.uuid,
        value: false,
      });
      lead.read = false;
      await this.refreshPage();
    },
    async refreshPage() {
      await this.$store.dispatch('enquiry/getEnquiries', this.page);
      let found = false;
      for (let lead of this.leads) {
        if (lead.uuid === this.selectedLead.uuid) {
          found = true;
        }
      }
      if (!found) {
        this.selectedLead = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.my--leads-content {
  margin: 16px 0;
  border: solid 1px $color-gray-1;
}
.right--col {
  padding: 0;
}
.all--leads {
  height: 400px;
  overflow-y: scroll;
  @media #{$phones} {
    height: 200px;
    border-bottom: solid 4px $color-gray-1;
  }
}
.lead--summary {
  padding: 20px 16px;
  background-color: $color-beliruma-1-light;
  border-bottom: solid 1px $color-gray-1;
  cursor: pointer;
  &.read {
    background-color: $color-white;
  }
  .lead--name {
    margin-bottom: 12px;
  }
  .lead--message {
    margin-bottom: 4px;
  }
  .lead--created-at {
    text-align: right;
    color: $color-text-gray-1;
  }
}
.selected--lead {
  padding: 32px 32px 32px 8px;
  @media #{$phones} {
    padding: 8px 28px 24px 32px;
  }
}
.lead--header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 24px;
  .lead--actions {
    display: flex;
    align-items: start;
    margin-left: 16px;
    .single--action {
      width: 80px;
      padding: 8px 8px;
      cursor: pointer;
      font-size: $font-xs;
      text-align: center;
      @media #{$phones} {
        width: 50px;
        padding: 4px 4px;
        font-size: $font-xxs;
      }
      .v-icon {
        margin-bottom: 8px;
      }
    }
  }
}
.lead--name {
  font-family: Poppins-SemiBold;
  margin-bottom: 8px;
}
.lead--message {
  margin-bottom: 8px;
}
.lead--listing {
  margin-bottom: 24px;
  a {
    color: $color-beliruma-1;
  }
}
.lead--created-at {
  font-size: $font-sm;
}
.lead--email {
  display: flex;
  align-items: center;
  color: $color-beliruma-1;
  margin-bottom: 16px;
  text-decoration: none;
  img {
    width: 24px;
    margin-right: 12px;
  }
}
.lead--phone {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: $color-text-black;
  text-decoration: none;
  img {
    width: 24px;
    margin-right: 12px;
  }
}
.empty--lead {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-xxl;
  font-family: Poppins-SemiBold;
  color: $color-beliruma-1;
  text-align: center;
  margin: auto;
  padding: 96px;
  @media #{$phones} {
    font-size: $font-lg;
    padding: 48px;
  }
}
</style>
