<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container my--leads-container mb-4">
      <SectionLoader :show="loading" />
      <leads-header />
      <leads-content />
      <pagination
        :push-state="false"
        :meta="leadsMeta.pagination"
        @changePage="changePage"
        :maxVisibleButtons="5"
        v-if="leadsMeta"
      />
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import LeadsHeader from '@/components/leads/leads-header';
import LeadsContent from '@/components/leads/leads-content';
import SectionLoader from '@/components/content-loading/section-loading';
import Pagination from '@/components/utils/fractal-pagination';
import { mapState } from 'vuex';

export default {
  name: 'index',
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    LeadsHeader,
    LeadsContent,
    SectionLoader,
    Pagination,
  },
  computed: {
    ...mapState({
      loading: (state) => state.enquiry.loading,
      leads: (state) => state.enquiry.leads,
      leadsMeta: (state) => state.enquiry.leadsMeta,
    }),
    breadcrumbs() {
      const currentName = this.$t('home.sidebarMenu.myLeads');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {
    async changePage(page) {
      this.$store.commit('enquiry/SET_SELECTED_LEAD', null);
      await this.$store.dispatch('enquiry/getEnquiries', page);
    },
  },
  async mounted() {
    this.$store.commit('enquiry/RESTORE_DEFAULT_VALUE');
    await this.$store.dispatch('enquiry/getEnquiries', 1);
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.my--leads-container {
  position: relative;
  @media #{$phones} {
    padding: 16px 24px;
  }
}
</style>
