<template>
  <section class="my--leads-header">
    <div class="dashboard--search-container">
      <v-select
        v-model="selectedArchive"
        :items="archiveFilters"
        :label="$t('leads.archiveFilter')"
        item-value="id"
        item-text="name"
        solo
        hide-details
        class="basic--input"
      ></v-select>
      <v-select
        v-model="selectedRead"
        :items="readFilters"
        :label="$t('leads.readFilter')"
        item-value="id"
        item-text="name"
        solo
        hide-details
        class="basic--input"
      ></v-select>
    </div>
  </section>
</template>

<script>
export default {
  name: 'leads-header',
  components: {},
  data: () => ({
    archiveFilters: [
      {
        id: false,
        name: 'Kotak Pesan',
      },
      {
        id: true,
        name: 'Arsip',
      },
    ],
    readFilters: [
      {
        id: null,
        name: 'Semua Status Baca',
      },
      {
        id: true,
        name: 'Telah Dibaca',
      },
      {
        id: false,
        name: 'Belum Dibaca',
      },
    ],
  }),
  computed: {
    selectedArchive: {
      get() {
        return this.$store.state.enquiry.archive;
      },
      set(value) {
        this.$store.commit('enquiry/SET_ARCHIVE', value);
        this.$store.commit('enquiry/SET_SELECTED_LEAD', null);
        this.$store.dispatch('enquiry/getEnquiries', 1);
      },
    },
    selectedRead: {
      get() {
        return this.$store.state.enquiry.read;
      },
      set(value) {
        this.$store.commit('enquiry/SET_READ', value);
        this.$store.commit('enquiry/SET_SELECTED_LEAD', null);
        this.$store.dispatch('enquiry/getEnquiries', 1);
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.basic--input {
  max-width: 250px;
  margin-right: 16px;
  @media #{$phones} {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
